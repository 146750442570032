import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withModifiers as _withModifiers, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock, Transition as _Transition } from "vue"
import _imports_0 from '@/assets/icons/arrow-down-dropdown.svg'


const _hoisted_1 = { class: "block truncate" }
const _hoisted_2 = {
  key: 0,
  class: "p-2"
}
const _hoisted_3 = { class: "text-center text-gray-400 p-2" }
const _hoisted_4 = {
  key: 1,
  class: "p-2"
}
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = { ref: "optionsList" }
const _hoisted_7 = ["onClick", "data-id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createBlock(_component_Menu, {
    as: "div",
    class: "relative"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_MenuButton, { class: "mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 ps-3 rtl:ps-8 ltr:pe-6 text-start cursor-pointer focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm hover:bg-indigo-100" }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.selectedOptionLabel), 1),
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "absolute inset-y-0 rtl:start-0 ltr:end-0 flex items-center pe-2 rtl:ps-2 pointer-events-none" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Arrow Down",
              class: "w-4 h-4"
            })
          ], -1))
        ]),
        _: 1
      }),
      _createVNode(_Transition, {
        "enter-active-class": "transition ease-out duration-100",
        "enter-from-class": "opacity-0 scale-95",
        "enter-to-class": "opacity-100 scale-100",
        "leave-active-class": "transition ease-in duration-75",
        "leave-from-class": "opacity-100 scale-100",
        "leave-to-class": "opacity-0 scale-95"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MenuItems, { class: "absolute mt-1 w-full rounded-lg ring-1 ring-gray-300 bg-white shadow-lg z-10 max-h-60 overflow-auto focus:outline-none text-sm" }, {
            default: _withCtx(() => [
              (!_ctx.items.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('common.no_data')), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.items.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
                      onKeydown: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
                      type: "text",
                      placeholder: _ctx.$t('common.search'),
                      class: "w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    }, null, 40, _hoisted_5), [
                      [_vModelText, _ctx.searchQuery]
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("ul", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item) => {
                  return (_openBlock(), _createBlock(_component_MenuItem, {
                    key: _ctx.itemValue(item),
                    as: "template"
                  }, {
                    default: _withCtx(({ active }) => [
                      _createElementVNode("li", {
                        onClick: ($event: any) => (_ctx.selectOption(_ctx.itemValue(item))),
                        class: _normalizeClass(["cursor-pointer px-4 py-2 hover:bg-indigo-100 text-gray-700", { 'bg-indigo-100': active || _ctx.itemValue(item) === _ctx.modelValue }]),
                        "data-id": _ctx.itemValue(item)
                      }, _toDisplayString(_ctx.itemLabel(item)), 11, _hoisted_7)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ], 512)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}