import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-wrap gap-4" }
const _hoisted_2 = ["onClick"]

import { computed, watch } from "vue";
import { useStore } from "vuex";
import Spinner from "./Spinner.vue";
import MessageLayout from "./MessageLayout.vue";
import { Platform } from "@/interfaces/Platform";

// Vuex Store

export default /*@__PURE__*/_defineComponent({
  __name: 'PlatformSelector',
  emits: ["platform-selected"],
  setup(__props, { emit: __emit }) {

const store = useStore();

// Emits
const emit = __emit;

// Computed Properties
const platforms = computed<Platform[]>(() => store.state.platforms.platforms);
const selectedPlatform = computed<Platform | null>(() => store.state.platforms.selectedPlatform);
const isLoading = computed<boolean>(() => store.state.platforms.isLoading);
const hasError = computed<boolean>(() => store.state.platforms.hasError);

// Methods
const notifyPlatformSelected = (platform: Platform) => {
  store.dispatch("platforms/selectPlatform", platform);
  emit("platform-selected", platform);
};

// Fetch Platforms and Preselect
if (!store.state.platforms.isCached) {
  store.dispatch("platforms/fetchPlatforms").then(() => {
    let platformToSelect: Platform | undefined;

    // If platformId is invalid or not provided, default to the cached platform or the first platform
    if (!platformToSelect) {
      platformToSelect = selectedPlatform.value || platforms.value[0];
    }

    if (platformToSelect) {
      notifyPlatformSelected(platformToSelect);
    } else {
      console.warn("No platforms available to select.");
    }
  });
} else {
  // Use cached data directly
  if (selectedPlatform.value) {
    emit("platform-selected", selectedPlatform.value);
  }
}

// Watch for Changes in platformId Prop

return (_ctx: any,_cache: any) => {
  const _directive_can = _resolveDirective("can")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isLoading.value && !hasError.value)
      ? (_openBlock(), _createBlock(Spinner, {
          key: 0,
          showLoadingText: false
        }))
      : _createCommentVNode("", true),
    (hasError.value && !isLoading.value)
      ? (_openBlock(), _createBlock(MessageLayout, {
          key: 1,
          message: _ctx.$t('store.error_fetching_data')
        }, null, 8, ["message"]))
      : _createCommentVNode("", true),
    (!isLoading.value && !hasError.value)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(platforms.value, (platform) => {
          return (_openBlock(), _createElementBlock("div", {
            key: platform.id,
            class: "flex-none"
          }, [
            _createElementVNode("button", {
              class: _normalizeClass(["ring-1 ring-gray-300 py-2 px-4 rounded-md hover:bg-indigo-100 whitespace-nowrap", {
          'bg-indigo-100 ring-2 ring-indigo-400 font-bold pointer-events-none': selectedPlatform.value && selectedPlatform.value.id === platform.id,
        }]),
              onClick: _withModifiers(($event: any) => (notifyPlatformSelected(platform)), ["prevent"])
            }, _toDisplayString(platform.name), 11, _hoisted_2)
          ]))
        }), 128))
      : _createCommentVNode("", true)
  ])), [
    [_directive_can, 'com.ltm.permissions.platforms.view.platform']
  ])
}
}

})