import apiClient from './apiService'
import { ExamCorrection, Exam, ExamForm, ExamQueryParams, ExamStudent, ExamStudentQueryParams, ExamStudentResponse } from '../interfaces/Exam'
import { School } from '@/interfaces/School';
import { Student } from '@/interfaces/Student';
import { Question } from '@/interfaces/Question';

const endpoint = '/exams';

export default {

  async getAllExams(params?: ExamQueryParams): Promise<ApiResponse<Exam[]>> {
    const response = await apiClient.get<ApiResponse<Exam[]>>(endpoint, { params })
    return response.data
  },

  async getExamById(id: number): Promise<ApiResponse<Exam>> {
    const response = await apiClient.get<ApiResponse<Exam>>(`${endpoint}/${id}`)
    return response.data;
  },

  async getExamForm(platform_id: number): Promise<ExamForm> {
    const response = await apiClient.post<{ status: string; message: string; data: ExamForm }>(`${endpoint}/form`, { platform_id: platform_id })
    return response.data.data;
  },

  async createExam(exam: Exam): Promise<Exam> {
    const response = await apiClient.post<{ status: string; message: string; data: Exam }>(endpoint, exam)
    return response.data.data
  },

  async updateExam(exam: Exam): Promise<Exam> {
    const response = await apiClient.put<{ status: string; message: string; data: Exam }>(`${endpoint}/${exam.id}`, exam)
    return response.data.data
  },

  async publishExam(id: number): Promise<Exam> {
    const response = await apiClient.put<{ status: string; message: string, data: Exam }>(`${endpoint}/${id}/publish`)
    return response.data.data
  },

  async publishMultipleExams(ids: number[]): Promise<Exam[]> {
    const response = await apiClient.post<{ status: string; message: string, data: Exam[] }>(`${endpoint}/bulk/publish`, { "exam_ids": ids })
    return response.data.data
  },

  async unpublishExam(id: number): Promise<Exam> {
    const response = await apiClient.put<{ status: string; message: string, data: Exam }>(`${endpoint}/${id}/unpublish`)
    return response.data.data
  },

  async unpublishMultipleExams(ids: number[]): Promise<Exam[]> {
    const response = await apiClient.post<{ status: string; message: string, data: Exam[] }>(`${endpoint}/bulk/unpublish`, { "exam_ids": ids })
    return response.data.data
  },

  async deleteExam(id: number): Promise<Exam> {
    const response = await apiClient.delete<{ status: string; message: string, data: Exam }>(`${endpoint}/${id}`)
    return response.data.data
  },

  async deleteMultipleExams(ids: number[]): Promise<Exam[]> {
    const response = await apiClient.post<{ status: string; message: string, data: Exam[] }>(`${endpoint}/bulk/delete`, { "exam_ids": ids })
    return response.data.data
  },

  async duplicateExam(id: number): Promise<Exam> {
    const response = await apiClient.get<{ status: string; message: string, data: Exam }>(`${endpoint}/${id}/duplicate`)
    return response.data.data
  },

  async previewExam(id: number): Promise<ApiResponse<Exam>> {
    const response = await apiClient.get<ApiResponse<Exam>>(`${endpoint}/${id}/preview`)
    return response.data;
  },

  // Assignation.

  async assignExamToStudents(examId: number, form: any): Promise<ApiResponse<Student[]>> {
    console.log(form);
    const response = await apiClient.post<ApiResponse<Student[]>>(`${endpoint}/${examId}/assign/students`, form)
    return response.data;
  },

  async getAssignedStudents(id: number, params: ExamStudentQueryParams): Promise<ApiResponse<ExamStudent[]>> {
    const response = await apiClient.get<ApiResponse<ExamStudent[]>>(`${endpoint}/${id}/assigned/students`, { params })
    return response.data
  },

  async getSchoolsForAssignation(examId: number): Promise<School[]> {
    const response = await apiClient.get<{ status: string; message: string; data: School[] }>(`${endpoint}/${examId}/assignation/schools`);
    return response.data.data;
  },

  async getActiveSchoolStudents(examId: number, schoolId: number): Promise<Student[]> {
    const response = await apiClient.get<{ status: string; message: string; data: Student[] }>(`${endpoint}/${examId}/assignation/schools/${schoolId}/students`);
    return response.data.data;
  },

  async reassignExamToStudents(examId: number, form: any): Promise<ApiResponse<Student[]>> {
    const response = await apiClient.post<ApiResponse<Student[]>>(`${endpoint}/${examId}/reassign/students`, form)
    return response.data;
  },

  // Correction.

  async getExamCorrection(examId: number, studentId: number): Promise<ApiResponse<ExamCorrection>> {
    const response = await apiClient.get<ApiResponse<ExamCorrection>>(`${endpoint}/${examId}/students/${studentId}/correction`);
    return response.data;
  },

  async submitCorrection(responses: any): Promise<ExamStudentResponse> {
    const response = await apiClient.post<{ status: string; message: string, data: ExamStudentResponse }>(`${endpoint}/submit/correction`, responses);
    return response.data.data;
  },

}