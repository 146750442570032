<template>
  <div>
    <button @click.prevent="showConfirmationDialog = true" :class="buttonClass">
      <slot></slot>
    </button>
    <ConfirmationDialog :show="showConfirmationDialog" :title="title" :message="message" @confirm="handleConfirm" @cancel="handleCancel" />
  </div>
</template>

<script>
import { ref } from "vue";
import ConfirmationDialog from "./ConfirmationDialog.vue";

export default {
  name: "ButtonConfirmation",
  components: {
    ConfirmationDialog,
  },
  props: {
    title: {
      type: String,
      default: "Confirm Action",
    },
    message: {
      type: String,
      default: "Are you sure you want to proceed?",
    },
    onConfirm: {
      type: Function,
      required: true,
    },
    buttonClass: {
      type: String,
      default: "min-w-5 min-h-5 flex rounded-md", // here can be a default class for the button if needed.
    },
  },
  setup(props) {
    const showConfirmationDialog = ref(false);

    const handleConfirm = () => {
      props.onConfirm();
      showConfirmationDialog.value = false;
    };

    const handleCancel = () => {
      showConfirmationDialog.value = false;
    };

    return {
      showConfirmationDialog,
      handleConfirm,
      handleCancel,
    };
  },
};
</script>
