import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/icons/record.svg'
import _imports_1 from '@/assets/icons/stop.svg'
import _imports_2 from '@/assets/icons/pause.svg'
import _imports_3 from '@/assets/icons/play.svg'
import _imports_4 from '@/assets/icons/retry.svg'
import _imports_5 from '@/assets/icons/disk-save.svg'


const _hoisted_1 = { class: "exam-font flex-1 ring-1 ring-gray-300 rounded-md min-w-64 max-w-64 min-h-64 p-8 flex flex-col items-center justify-center" }
const _hoisted_2 = {
  key: 0,
  class: "text-center"
}
const _hoisted_3 = { class: "text-gray-500 font-semibold mt-4" }
const _hoisted_4 = {
  key: 1,
  class: "w-full"
}
const _hoisted_5 = { class: "flex flex-col items-center justify-center gap-y-4" }
const _hoisted_6 = { class: "text-3xl font-light" }
const _hoisted_7 = {
  key: 2,
  class: "w-full"
}
const _hoisted_8 = { class: "flex justify-center items-center gap-x-4 mb-4" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = {
  key: 0,
  src: _imports_2,
  class: "w-8 h-8"
}
const _hoisted_11 = {
  key: 1,
  src: _imports_3,
  class: "w-8 h-8"
}
const _hoisted_12 = ["disabled"]
const _hoisted_13 = ["disabled"]
const _hoisted_14 = ["max", "value"]
const _hoisted_15 = { class: "flex justify-between text-sm text-gray-500" }
const _hoisted_16 = {
  key: 3,
  class: "w-full"
}
const _hoisted_17 = { class: "flex justify-center items-center gap-x-4 mb-4" }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = {
  key: 0,
  src: _imports_2,
  class: "w-8 h-8"
}
const _hoisted_20 = {
  key: 1,
  src: _imports_3,
  class: "w-8 h-8"
}
const _hoisted_21 = ["disabled"]
const _hoisted_22 = ["max", "value"]
const _hoisted_23 = { class: "flex justify-between text-sm text-gray-500" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = {
  key: 4,
  class: "mt-4"
}
const _hoisted_26 = { class: "text-gray-500" }

import { ref, onMounted, onUnmounted } from "vue";
import mediaService from "@/services/mediaService";

// Define the states

export default /*@__PURE__*/_defineComponent({
  __name: 'AudioRecorder',
  props: {
  question: {
    type: Object,
    required: true,
  },
  savedAudio: {
    type: String,
    default: null,
  },
  playModeOnly: {
    type: Boolean,
    default: false,
  },
},
  emits: ["recordingComplete"],
  setup(__props, { emit: __emit }) {

const state = ref<"readyToRecord" | "recording" | "localPlayback" | "remotePlayback">("remotePlayback"); // Start in 'remotePlayback' state

// Props
const props = __props;

// State management variables
const isRecording = ref(false);
const audioUrl = ref<string | null>(props.savedAudio);
const recordingTime = ref(0);
const isPlaying = ref(false);
const audioBlob = ref<Blob | null>(null);
const currentTime = ref(0);
const duration = ref(0);
const isUploading = ref(false);
const uploadSuccess = ref(!!props.savedAudio);

let mediaRecorder: MediaRecorder | null = null;
let audioChunks: Blob[] = [];
let recordingInterval: number | null = null;
let audio: HTMLAudioElement | null = null;
let stream: MediaStream | null = null;

const emit = __emit;

// Start recording
const startRecording = async () => {
  try {
    stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorder = new MediaRecorder(stream, { mimeType: "audio/webm" });
    audioChunks = [];

    mediaRecorder.ondataavailable = (event) => {
      audioChunks.push(event.data);
    };

    mediaRecorder.onstop = () => {
      audioBlob.value = new Blob(audioChunks, { type: "audio/webm" });
      audioUrl.value = URL.createObjectURL(audioBlob.value); // Set local audio URL
      duration.value = recordingTime.value;
      initAudio();
      state.value = "localPlayback"; // Switch to local playback state
    };

    mediaRecorder.start();
    isRecording.value = true;
    recordingTime.value = 0;
    state.value = "recording"; // Switch to recording state
    recordingInterval = window.setInterval(() => {
      recordingTime.value++;
      if (recordingTime.value >= 300) {
        stopRecording();
      }
    }, 1000);
  } catch (error) {
    console.error("Error starting recording:", error);
  }
};

// Stop recording
const stopRecording = () => {
  if (mediaRecorder) {
    mediaRecorder.stop();
    isRecording.value = false;
    if (recordingInterval) {
      clearInterval(recordingInterval);
    }
    stopMicrophoneStream();
  }
};

// Stop the microphone stream
const stopMicrophoneStream = () => {
  if (stream) {
    stream.getTracks().forEach((track) => track.stop());
    stream = null;
  }
};

// Initialize audio playback
const initAudio = () => {
  if (audioUrl.value) {
    // Create the audio element with the audio URL
    audio = new Audio(audioUrl.value);

    // Set preload to 'auto' to buffer the file in the background
    audio.preload = "metadata";

    // Explicitly load the audio (if needed)
    audio.load();

    // Add event listeners for metadata loading
    audio.addEventListener("loadedmetadata", () => {
      handleMetadataLoaded();
    });

    audio.addEventListener("canplaythrough", () => {
      handleMetadataLoaded();
    });

    // Other event listeners (playback controls, error handling, etc.)
    audio.addEventListener("ended", () => {
      isPlaying.value = false;
      currentTime.value = 0;
    });

    audio.addEventListener("timeupdate", () => {
      currentTime.value = audio?.currentTime || 0;
    });

    audio.addEventListener("play", () => {
      isPlaying.value = true;
    });

    audio.addEventListener("pause", () => {
      isPlaying.value = false;
    });

    audio.addEventListener("error", (e) => {
      console.error("Error loading audio:", e);
    });

    // Start the continuous polling for valid duration
    // pollForValidDuration();
  }
};

// Polling function to continuously check for a valid duration until it is retrieved
const pollForValidDuration = () => {
  const pollInterval = setInterval(() => {
    if (audio && !isNaN(audio.duration) && audio.duration !== Infinity) {
      duration.value = audio.duration; // Set valid duration
      // console.log("Duration loaded successfully:", duration.value);
      clearInterval(pollInterval); // Stop polling when valid duration is found
    } else {
      // console.warn("Duration not yet available, retrying...");
    }
  }, 500); // Poll every 500ms, continue indefinitely until duration is available
};

// Handle metadata loaded and ensure duration is fetched
const handleMetadataLoaded = () => {
  if (audio?.duration && !isNaN(audio.duration) && audio.duration !== Infinity) {
    duration.value = audio.duration; // Set valid duration
    // console.log("Duration loaded successfully:", duration.value);
  } else {
    // console.warn("Duration not yet available, retrying...");
    pollForValidDuration(); // Continue polling if duration is not valid
  }
};

// Toggle Play/Pause
const togglePlayPause = () => {
  if (audio) {
    if (isPlaying.value) {
      audio.pause();
    } else {
      audio.play();
    }
    isPlaying.value = !isPlaying.value;
  }
};

// Seek Audio
const seekAudio = (event: Event) => {
  if (audio) {
    const target = event.target as HTMLInputElement;
    audio.currentTime = Number(target.value);
    currentTime.value = audio.currentTime;
  }
};

// Re-record
const reRecord = () => {
  if (audio) {
    audio.pause();
    audio = null;
  }
  audioUrl.value = null;
  audioBlob.value = null;
  isPlaying.value = false;
  currentTime.value = 0;
  duration.value = 0;
  recordingTime.value = 0;
  uploadSuccess.value = false;
  state.value = "readyToRecord"; // Reset to ready-to-record state
  stopMicrophoneStream();
};

// Confirm and save recording
const confirmRecording = async () => {
  if (audioBlob.value) {
    isUploading.value = true;
    try {
      const file = new File([audioBlob.value], "audio.webm", { type: "audio/webm" });
      const response = await mediaService.uploadMedia(file, "exams/questions/" + props.question.id + "/audio_responses");

      if (response.status) {
        uploadSuccess.value = true;
        emit("recordingComplete", response.data.relative_path); // Emit saved path
        audioUrl.value = response.data.preview_url; // Update audio URL to saved audio
        state.value = "remotePlayback"; // Switch to remote playback state
        initAudio();
      } else {
        console.error("Upload failed:", response.message);
      }
    } catch (error) {
      console.error("Error uploading audio:", error);
    } finally {
      isUploading.value = false;
    }
  }
};

// Format time to MM:SS
const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};

// On mount, initialize audio based on playModeOnly and savedAudio
onMounted(() => {
  if (props.playModeOnly && props.savedAudio) {
    // If playModeOnly is true and there's saved audio, allow playback only
    audioUrl.value = props.savedAudio;
    state.value = "remotePlayback";
    initAudio();
  } else if (!props.playModeOnly && props.savedAudio) {
    // If playModeOnly is false and saved audio exists, allow playback and recording
    audioUrl.value = props.savedAudio;
    state.value = "remotePlayback";
    initAudio();
  } else if (!props.playModeOnly && !props.savedAudio) {
    // If playModeOnly is false and there's no saved audio, allow recording
    state.value = "readyToRecord";
  } else {
    // If playModeOnly is true but there's no saved audio, disable recording and playback (or set default behavior)
    state.value = "readyToRecord"; // or set an appropriate fallback state
  }
});

// Clean up on unmount
onUnmounted(() => {
  if (recordingInterval) {
    clearInterval(recordingInterval);
  }
  if (audio) {
    audio.pause();
    audio = null;
  }
  stopMicrophoneStream();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (state.value === 'readyToRecord')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("button", {
            onClick: startRecording,
            class: "bg-white p-2 rounded-full hover:bg-gray-200"
          }, _cache[0] || (_cache[0] = [
            _createElementVNode("img", {
              src: _imports_0,
              class: "w-16 h-16"
            }, null, -1)
          ])),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("answers.types.audio_response.hint")), 1)
        ]))
      : _createCommentVNode("", true),
    (state.value === 'recording')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              onClick: stopRecording,
              class: "bg-red-500 p-4 rounded-full"
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("img", {
                src: _imports_1,
                class: "w-8 h-8"
              }, null, -1)
            ])),
            _createElementVNode("span", _hoisted_6, _toDisplayString(formatTime(recordingTime.value)), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (state.value === 'localPlayback')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              onClick: togglePlayPause,
              class: "bg-white p-2 rounded-full hover:bg-blue-200 disabled:opacity-25 disabled:cursor-not-allowed",
              disabled: isUploading.value
            }, [
              (isPlaying.value)
                ? (_openBlock(), _createElementBlock("img", _hoisted_10))
                : (_openBlock(), _createElementBlock("img", _hoisted_11))
            ], 8, _hoisted_9),
            _createElementVNode("button", {
              onClick: reRecord,
              class: "bg-white p-2 rounded-full hover:bg-red-200 disabled:opacity-25 disabled:cursor-not-allowed",
              disabled: isPlaying.value || isUploading.value
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("img", {
                src: _imports_4,
                class: "w-8 h-8"
              }, null, -1)
            ]), 8, _hoisted_12),
            _createElementVNode("button", {
              onClick: confirmRecording,
              class: "bg-white p-2 rounded-full hover:bg-green-200 disabled:opacity-25 disabled:cursor-not-allowed",
              disabled: isPlaying.value || isUploading.value
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("img", {
                src: _imports_5,
                class: "w-8 h-8"
              }, null, -1)
            ]), 8, _hoisted_13)
          ]),
          _createElementVNode("input", {
            type: "range",
            min: "0",
            max: duration.value,
            value: currentTime.value,
            onInput: seekAudio,
            class: "w-full h-2 bg-gray-200 rounded-lg appearance-auto cursor-pointer"
          }, null, 40, _hoisted_14),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("span", null, _toDisplayString(formatTime(currentTime.value)), 1),
            _createElementVNode("span", null, _toDisplayString(formatTime(duration.value)), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (state.value === 'remotePlayback')
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("button", {
              onClick: togglePlayPause,
              class: "bg-white p-2 rounded-full hover:bg-blue-200 disabled:opacity-25 disabled:cursor-not-allowed",
              disabled: isUploading.value
            }, [
              (isPlaying.value)
                ? (_openBlock(), _createElementBlock("img", _hoisted_19))
                : (_openBlock(), _createElementBlock("img", _hoisted_20))
            ], 8, _hoisted_18),
            (!__props.playModeOnly)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: reRecord,
                  class: "bg-white p-2 rounded-full hover:bg-red-200 disabled:opacity-25 disabled:cursor-not-allowed",
                  disabled: isPlaying.value || isUploading.value
                }, _cache[4] || (_cache[4] = [
                  _createElementVNode("img", {
                    src: _imports_4,
                    class: "w-8 h-8"
                  }, null, -1)
                ]), 8, _hoisted_21))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("input", {
            type: "range",
            min: "0",
            max: duration.value,
            value: currentTime.value,
            onInput: seekAudio,
            class: "w-full h-2 bg-gray-200 rounded-lg appearance-auto cursor-pointer"
          }, null, 40, _hoisted_22),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("span", null, _toDisplayString(formatTime(currentTime.value)), 1),
            (duration.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(formatTime(duration.value)), 1))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (isUploading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
          _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.$t("common.loading")), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})