import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/icons/delete.svg'
import _imports_1 from '@/assets/icons/file.svg'


const _hoisted_1 = { class: "max-w-72" }
const _hoisted_2 = {
  key: 0,
  class: "h-64 w-full min-w-64 object-cover mb-4 ring-2 ring-gray-300 rounded-3xl p-4 shadow-lg"
}
const _hoisted_3 = { class: "flex flex-col items-center p-8 relative" }
const _hoisted_4 = ["href"]
const _hoisted_5 = {
  key: 1,
  class: "h-64 w-full min-w-64 object-cover mb-4 ring-2 ring-gray-300 rounded-3xl p-4 shadow-lg"
}
const _hoisted_6 = { class: "flex flex-col items-center p-8 relative" }
const _hoisted_7 = ["href"]
const _hoisted_8 = {
  key: 2,
  class: "h-64 w-full min-w-64 object-cover mb-4 ring-2 ring-gray-300 rounded-3xl p-4 shadow-lg"
}
const _hoisted_9 = { class: "flex flex-col items-center p-8" }
const _hoisted_10 = ["href"]
const _hoisted_11 = ["for"]
const _hoisted_12 = { class: "flex flex-col items-center p-8" }
const _hoisted_13 = { class: "bg-transparent font-bold cursor-pointer hover:bg-gray-100 rounded-xl p-1" }
const _hoisted_14 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.uploadedMediaUrl && _ctx.mediaUrl)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (!_ctx.previewOnly)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "absolute top-1 end-1",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.removeUploadedFile && _ctx.removeUploadedFile(...args)))
                }, _cache[9] || (_cache[9] = [
                  _createElementVNode("img", {
                    src: _imports_0,
                    class: "w-6 h-6 cursor-pointer hover:bg-gray-300 hover:rounded-full"
                  }, null, -1)
                ])))
              : _createCommentVNode("", true),
            _createElementVNode("a", {
              href: _ctx.mediaUrl,
              target: "_blank",
              class: "text-indigo-500 text-center"
            }, [
              _cache[10] || (_cache[10] = _createElementVNode("img", {
                src: _imports_1,
                class: "w-32 h-32 mb-2"
              }, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("common.preview")), 1)
            ], 8, _hoisted_4)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.uploadedMediaUrl)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (!_ctx.previewOnly)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "absolute top-1 end-1",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.removeUploadedFile && _ctx.removeUploadedFile(...args)))
                }, _cache[11] || (_cache[11] = [
                  _createElementVNode("img", {
                    src: _imports_0,
                    class: "w-6 h-6 cursor-pointer hover:bg-gray-300 hover:rounded-full"
                  }, null, -1)
                ])))
              : _createCommentVNode("", true),
            _createElementVNode("a", {
              href: _ctx.uploadedMediaUrl,
              target: "_blank",
              class: "text-indigo-500 text-center"
            }, [
              _cache[12] || (_cache[12] = _createElementVNode("img", {
                src: _imports_1,
                class: "w-32 h-32 mb-2"
              }, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("common.preview")), 1)
            ], 8, _hoisted_7)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.previewUrl)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("a", {
              href: _ctx.previewUrl,
              target: "_blank",
              class: "text-indigo-500 text-center"
            }, [
              _cache[13] || (_cache[13] = _createElementVNode("img", {
                src: _imports_1,
                class: "w-32 h-32 mb-2"
              }, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("common.preview")), 1)
            ], 8, _hoisted_10)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("label", {
      for: _ctx.uniqueId,
      class: "block"
    }, [
      (!_ctx.selectedFile && !_ctx.previewOnly && !_ctx.mediaUrl)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["border-2 border-dashed border-gray-300 rounded-md p-5 text-center cursor-pointer transition-colors duration-300 min-w-64", { 'bg-gray-100': _ctx.isDragging }]),
            onDragover: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
            onDrop: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleDrop && _ctx.handleDrop(...args)), ["prevent"])),
            onDragenter: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.isDragging = true), ["prevent"])),
            onDragleave: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleDragLeave && _ctx.handleDragLeave(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_12, [
              _cache[14] || (_cache[14] = _createStaticVNode("<i class=\"mb-4\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"40\" height=\"40\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4\"></path><polyline points=\"17 8 12 3 7 8\"></polyline><line x1=\"12\" y1=\"3\" x2=\"12\" y2=\"15\"></line></svg></i>", 1)),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("common.drag_and_drop")), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("common.or")), 1),
              _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t("common.browse")), 1)
            ])
          ], 34))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        id: _ctx.uniqueId,
        type: "file",
        onChange: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleFileSelection && _ctx.handleFileSelection(...args))),
        accept: ".doc,.docx,.pdf,.png,.jpg,.jpeg",
        class: "hidden"
      }, null, 40, _hoisted_14)
    ], 8, _hoisted_11),
    (!_ctx.previewOnly && !_ctx.uploadedMediaUrl)
      ? (_openBlock(), _createElementBlock("a", {
          key: 3,
          onClick: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.uploadMedia && _ctx.uploadMedia(...args)), ["prevent"])),
          class: _normalizeClass([{ 'opacity-50 cursor-not-allowed': !_ctx.selectedFile || !_ctx.mediaLabel, 'cursor-pointer': _ctx.selectedFile && _ctx.mediaLabel }, "block w-full mt-3 py-2 px-4 bg-indigo-500 text-white text-center rounded-md"])
        }, _toDisplayString(_ctx.$t("common.upload")), 3))
      : _createCommentVNode("", true),
    (!_ctx.previewOnly && !_ctx.uploadedMediaUrl)
      ? _withDirectives((_openBlock(), _createElementBlock("a", {
          key: 4,
          onClick: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.removeFile && _ctx.removeFile(...args)), ["prevent"])),
          class: _normalizeClass([{ 'opacity-50 cursor-not-allowed': !_ctx.selectedFile || !_ctx.mediaLabel, 'cursor-pointer': _ctx.selectedFile && _ctx.mediaLabel }, "block w-full mt-3 py-2 px-4 bg-red-600 text-white text-center rounded-md"])
        }, _toDisplayString(_ctx.$t("common.remove")), 3)), [
          [_vShow, _ctx.selectedFile && _ctx.mediaLabel]
        ])
      : _createCommentVNode("", true)
  ]))
}