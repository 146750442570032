import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withModifiers as _withModifiers, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, Transition as _Transition, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/icons/arrow-down-dropdown.svg'


const _hoisted_1 = { class: "block truncate" }
const _hoisted_2 = {
  key: 0,
  class: "p-2"
}
const _hoisted_3 = { class: "text-center text-gray-400 p-2" }
const _hoisted_4 = {
  key: 1,
  class: "p-2"
}
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = { class: "flex items-center font-semibold" }
const _hoisted_7 = ["id", "checked", "onChange"]
const _hoisted_8 = ["for"]
const _hoisted_9 = { class: "ps-2 py-2" }
const _hoisted_10 = { class: "flex items-center py-1" }
const _hoisted_11 = ["id", "value"]
const _hoisted_12 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createBlock(_component_Menu, {
    as: "div",
    class: "relative"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_MenuButton, { class: "mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 ps-3 rtl:ps-8 text-start cursor-pointer focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm hover:bg-indigo-100" }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.selectedItems.length ? `${_ctx.selectedItems.length} ${_ctx.$t("common.selected")}` : _ctx.placeholder), 1),
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "absolute inset-y-0 rtl:start-0 ltr:end-0 flex items-center pe-2 rtl:ps-2 pointer-events-none" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Arrow Down",
              class: "w-4 h-4"
            })
          ], -1))
        ]),
        _: 1
      }),
      _createVNode(_Transition, {
        "enter-active-class": "transition ease-out duration-100",
        "enter-from-class": "opacity-0 scale-95",
        "enter-to-class": "opacity-100 scale-100",
        "leave-active-class": "transition ease-in duration-75",
        "leave-from-class": "opacity-100 scale-100",
        "leave-to-class": "opacity-0 scale-95"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MenuItems, { class: "absolute mt-1 w-full rounded-lg ring-1 ring-gray-300 bg-white shadow-xl z-10 max-h-60 overflow-auto focus:outline-none text-sm" }, {
            default: _withCtx(() => [
              (!_ctx.items.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("common.no_data")), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.items.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
                      onKeydown: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
                      type: "text",
                      placeholder: _ctx.$t('common.search'),
                      class: "w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    }, null, 40, _hoisted_5), [
                      [_vModelText, _ctx.searchQuery]
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredGroupedItems, (groupItems, groupName) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: groupName,
                  class: "p-4 border-b border-gray-300"
                }, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("input", {
                      type: "checkbox",
                      id: `group-checkbox-${groupName}`,
                      checked: _ctx.isGroupSelected(groupItems),
                      onChange: ($event: any) => (_ctx.toggleGroupSelection(groupItems)),
                      class: "me-2 h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    }, null, 40, _hoisted_7),
                    _createElementVNode("label", {
                      for: `group-checkbox-${groupName}`,
                      class: "cursor-pointer w-full"
                    }, _toDisplayString(groupName || _ctx.$t("common.all")), 9, _hoisted_8)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupItems, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: _ctx.itemValue(item),
                        onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
                      }, [
                        _createElementVNode("div", _hoisted_10, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            id: `item-checkbox-${_ctx.itemValue(item)}`,
                            value: _ctx.itemValue(item),
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedItems) = $event)),
                            onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.emitSelectionChange && _ctx.emitSelectionChange(...args))),
                            class: "me-2 h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                          }, null, 40, _hoisted_11), [
                            [_vModelCheckbox, _ctx.selectedItems]
                          ]),
                          _createElementVNode("label", {
                            for: `item-checkbox-${_ctx.itemValue(item)}`,
                            class: "cursor-pointer text-xs w-full"
                          }, _toDisplayString(_ctx.itemLabel(item)), 9, _hoisted_12)
                        ])
                      ]))
                    }), 128))
                  ])
                ]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}