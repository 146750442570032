import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/icons/upload-delete.svg'
import _imports_1 from '@/assets/icons/upload.svg'


const _hoisted_1 = { class: "flex items-center gap-x-2 min-w-fit" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["alt"]
const _hoisted_7 = {
  key: 6,
  class: "flex items-center justify-center gap-x-2"
}
const _hoisted_8 = ["for"]
const _hoisted_9 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.uploadedImageUrl && _ctx.imageUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.imageUrl,
          class: "h-10 w-10 object-cover rounded-lg"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.uploadedImageUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _ctx.uploadedImageUrl,
          class: "h-10 w-10 object-cover rounded-lg"
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    (!_ctx.uploadedImageUrl && _ctx.imageUrl)
      ? (_openBlock(), _createElementBlock("a", {
          key: 2,
          href: _ctx.imageUrl,
          target: "_blank",
          class: "text-blue-500 text-xs"
        }, _toDisplayString(_ctx.$t("common.preview_image")), 9, _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.uploadedImageUrl)
      ? (_openBlock(), _createElementBlock("a", {
          key: 3,
          href: _ctx.uploadedImageUrl,
          target: "_blank",
          class: "text-blue-500 text-xs"
        }, _toDisplayString(_ctx.$t("common.preview_image")), 9, _hoisted_5))
      : _createCommentVNode("", true),
    (_ctx.uploadedImageUrl || _ctx.imageUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 4,
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.removeFile && _ctx.removeFile(...args)), ["prevent"])),
          src: _imports_0,
          class: "cursor-pointer w-5 h-5",
          alt: _ctx.$t('common.remove')
        }, null, 8, _hoisted_6))
      : _createCommentVNode("", true),
    (_ctx.isUploadingImage)
      ? (_openBlock(), _createBlock(_component_Spinner, {
          key: 5,
          class: "w-5 h-5"
        }))
      : _createCommentVNode("", true),
    (!_ctx.selectedFile && !(_ctx.uploadedImageUrl || _ctx.imageUrl))
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("label", {
            for: _ctx.inputId,
            class: "cursor-pointer"
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("img", {
              src: _imports_1,
              class: "w-5 h-5"
            }, null, -1)
          ]), 8, _hoisted_8),
          _createElementVNode("input", {
            id: _ctx.inputId,
            type: "file",
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFileSelection && _ctx.handleFileSelection(...args))),
            accept: "image/*",
            class: "hidden"
          }, null, 40, _hoisted_9)
        ]))
      : _createCommentVNode("", true)
  ]))
}