import { Module } from 'vuex';
import schoolsService from '@/services/schoolsService';
import { School, SchoolQueryParams } from '@/interfaces/School';
import { Platform } from '@/interfaces/Platform';

interface SchoolsState {
    schools: School[];
    selectedPlatform: Platform | null;
    loading: boolean;
    loadingSchoolId: number | null;
    exportingSchoolId: number | null;
    params: SchoolQueryParams;
    meta: PaginationMeta;
    error: string | null;
}

const schoolsModule: Module<SchoolsState, any> = {
    namespaced: true,

    state: (): SchoolsState => ({
        schools: [],
        selectedPlatform: null,
        loading: false,
        loadingSchoolId: null,
        exportingSchoolId: null,
        params: {
            sort: 'id',
            order: 'asc',
            page: 1,
            filter: {},
        },
        meta: {
            current_page: 0,
            last_page: 0,
            per_page: 0,
            total: 0,
            first_page_number: 1,
            last_page_number: 0,
            next_page_number: null,
            prev_page_number: null,
            pages: [],
        },
        error: null,
    }),
    mutations: {
        setSchools(state, schools: School[]) {
            state.schools = schools;
        },
        setPlatform(state, platform: Platform | null) {
            state.selectedPlatform = platform;
        },
        setLoading(state, loading: boolean) {
            state.loading = loading;
        },
        setLoadingSchool(state, schoolId: number | null) {
            state.loadingSchoolId = schoolId; // the current school being processed
        },
        setExportingSchool(state, schoolId: number | null) {
            state.exportingSchoolId = schoolId; // the current school being exported
        },
        setParams(state, params: Partial<SchoolsState['params']>) {
            state.params = { ...state.params, ...params };
        },
        setPaginationMeta(state, meta: PaginationMeta) {
            state.meta = meta;
        },
        setError(state, error: string | null) {
            state.error = error;
        },
    },
    actions: {
        async fetchSchools({ commit, state }) {
            commit('setLoading', true);
            try {
                const response = await schoolsService.getAllSchools(state.params);
                commit('setSchools', response.data);
                commit('setPaginationMeta', response.meta);
            } catch (error) {
                commit('setError', error || 'Failed to fetch schools');
            } finally {
                commit('setLoading', false);
            }
        },
        async migrateSchoolReports({ commit, dispatch }, school_id: number) {
            commit('setLoadingSchool', school_id);
            try {
                await schoolsService.migrateSchoolReports(school_id);
            } catch (error) {
                commit('setError', error || 'Failed to migrate school reports');
            } finally {
                dispatch('fetchSchools');
                commit('setLoadingSchool', null);
            }
        },
        async exportSchoolReports({ commit, dispatch }, school_id: number) {
            commit('setExportingSchool', school_id);
            try {
                const response = await schoolsService.exportSchoolReports(school_id);
                const previewUrl = response?.data?.preview_url; // Ensure you access the correct path to the preview URL
                if (previewUrl) {
                    // Trigger file download
                    const link = document.createElement('a');
                    link.href = previewUrl;
                    link.download = ''; // Optionally, specify a filename if needed.
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    commit('setError', 'Failed to export school reports');
                }
            } catch (error) {
                commit('setError', error || 'Failed to export school reports');
            } finally {
                commit('setExportingSchool', null);
            }
        },
        updateParams({ commit, dispatch }, newParams: Partial<SchoolsState['params']>) {
            commit('setParams', newParams);
            dispatch('fetchSchools');
        },
        onPlatformSelected({ commit, dispatch }, platform: Platform) {
            commit('setPlatform', platform);
            commit('setParams', { page: 1, filter: { platform_id: platform.id } });
            dispatch('fetchSchools');
        }
    },
    getters: {
        allSchools: (state) => state.schools,
        isLoading: (state) => state.loading,
        hasError: (state) => !!state.error,
        errorMessage: (state) => state.error,
        loadingSchoolId: (state) => state.loadingSchoolId,
        exportingSchoolId: (state) => state.exportingSchoolId,
    },
};

export default schoolsModule;
