<template>
  <header class="bg-white" v-if="isAuthenticated">
    <nav class="flex items-center justify-between py-2 px-12 shadow-md rounded-b-2xl" aria-label="Global">
      <div class="flex flex-1 items-center">
        <a href="/" class="-m-1.5 p-1.5">
          <span class="sr-only">{{ $t("site.title") }}</span>
          <img class="h-16 w-auto" src="@/assets/images/logo.png" :alt="$t('site.title')" />
        </a>
      </div>

      <!-- Flex container for menu items and avatar -->
      <div class="flex gap-x-6 justify-end items-center">
        <a v-can="'com.ltm.permissions.exams.view.exam'" href="/exams" class="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-600" :class="{ 'text-indigo-600 pointer-events-none': $route.path === '/exams' }">{{ $t("exams.title") }}</a>
        <!-- Reports Permission currently specific for schools only since it's the only entity that has reports interaction. -->
        <a v-can="'com.ltm.permissions.schools.view.school'" href="/reports" class="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-600" :class="{ 'text-indigo-600 pointer-events-none': $route.path === '/reports' }">{{ $t("reports.title") }}</a>
        <a v-can="'com.ltm.permissions.questions.bank.view.question'" href="/questions-bank" class="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-600" :class="{ 'text-indigo-600 pointer-events-none': $route.path === '/questions-bank' }">{{ $t("questions_bank.title") }}</a>

        <!-- Settings Popover -->
        <Popover class="relative">
          <PopoverButton class="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 focus:outline-none hover:text-indigo-600" :class="{ 'text-indigo-600': $route.path.includes('/settings') }">
            <span>{{ $t("store.settings") }}</span>
            <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
          </PopoverButton>

          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
            <PopoverPanel class="absolute left-1/2 z-10 mt-4 flex w-screen max-w-min -translate-x-1/2 px-4">
              <div class="w-52 shrink rounded-xl bg-white p-4 text-sm leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5 space-y-1">
                <h3 class="px-4 py-2 text-sm text-gray-700 font-bold">{{ $t("store.exams_settings") }}</h3>
                <a v-can="'com.ltm.permissions.skills.view.skill'" href="/settings/exams/skills" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/exams/skills') }">{{ $t("store.skills") }}</a>
                <a v-can="'com.ltm.permissions.rounds.view.round'" href="/settings/exams/rounds" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/exams/rounds') }">{{ $t("store.rounds") }}</a>
                <a v-can="'com.ltm.permissions.grades.view.grade'" href="/settings/exams/grades" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/exams/grades') }">{{ $t("store.grades") }}</a>
                <a v-can="'com.ltm.permissions.levels.view.level'" href="/settings/exams/levels" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/exams/levels') }">{{ $t("store.levels") }}</a>
                <a v-can="'com.ltm.permissions.durations.view.duration'" href="/settings/exams/durations" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/exams/durations') }">{{ $t("store.durations") }}</a>

                <hr />
                <!-- Questions -->
                <h3 class="px-4 py-2 text-sm text-gray-700 font-bold">{{ $t("store.questions_settings") }}</h3>
                <a v-can="'com.ltm.permissions.cognitive.levels.view.cognitive.level'" href="/settings/questions/cognitive-levels" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/questions/cognitive-levels') }">{{ $t("store.cognitive-levels") }}</a>
                <a v-can="'com.ltm.permissions.difficulty.levels.view.difficulty.level'" href="/settings/questions/difficulty-levels" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/questions/difficulty-levels') }">{{ $t("store.difficulty-levels") }}</a>
                <a v-can="'com.ltm.permissions.learning.outcomes.view.learning.outcome'" href="/settings/questions/learning-outcomes" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/questions/learning-outcomes') }">{{ $t("store.learning-outcomes") }}</a>
                <a v-can="'com.ltm.permissions.depth.of.knowledges.view.depth.of.knowledge'" href="/settings/questions/depth-of-knowledge" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/questions/depth-of-knowledge') }">{{ $t("store.depth-of-knowledges") }}</a>

                <hr v-has-role="'com.ltm.roles.admin'" />
                <!-- Platforms -->
                <h3 v-has-role="'com.ltm.roles.admin'" class="px-4 py-2 text-sm text-gray-700 font-bold">{{ $t("store.system_settings") }}</h3>
                <a v-has-role="'com.ltm.roles.admin'" v-can="'com.ltm.permissions.platforms.view.platform'" href="/settings/platforms" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/platforms') }">{{ $t("store.platforms") }}</a>
                <a v-has-role="'com.ltm.roles.admin'" v-can="'com.ltm.permissions.users.view.users'" href="/settings/users" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md" :class="{ 'bg-indigo-100 pointer-events-none': $route.path.includes('/settings/users') }">{{ $t("users_management.title") }}</a>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>

        <Menu as="div">
          <MenuButton class="flex items-center focus:outline-none">
            <img class="h-7 w-7" src="@/assets/icons/avatar.svg" :alt="user.name" />
          </MenuButton>
          <transition enter-active-class="transition ease-out duration-100" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
            <MenuItems class="origin-top-end absolute end-36 mt-4 w-44 rounded-md shadow-2xl ring-gray-300 z-10">
              <MenuItem>
                <div class="w-52 shrink rounded-xl bg-white text-sm leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                  <a href="/profile" class="hover:cursor-pointer" dir="ltr" :class="{ 'pointer-events-none': $route.path === '/profile' }">
                    <div class="px-4 py-4 hover:bg-indigo-50 rounded-t-xl" :class="{ 'bg-indigo-50': $route.path === '/profile' }">
                      <p class="text-sm text-gray-700 font-bold">{{ user.name }}</p>
                      <p class="text-xs text-indigo-700">{{ user.roles.map((p) => p.name).join(", ") }}</p>
                      <p class="text-sm text-gray-700">{{ user.email }}</p>
                    </div>
                  </a>
                  <hr v-if="user" />
                  <ButtonConfirmation @confirm="logoutUser" buttonClass="w-full text-start p-4 text-sm text-red-500 hover:bg-gray-100 rounded-b-xl">
                    {{ $t("user.logout.title") }}
                  </ButtonConfirmation>
                </div>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>

        <!-- Language Switcher -->
        <LanguageSwitcher />
      </div>
    </nav>
  </header>
</template>

<script setup>
import { useCurrentUser } from "@/composables/auth/useCurrentUser";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

const { user, isAuthenticated, logoutUser } = useCurrentUser();
</script>
