import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/icons/speaker.svg'


const _hoisted_1 = { class: "relative w-8 h-8" }
const _hoisted_2 = { class: "w-full h-full transform -rotate-90" }
const _hoisted_3 = ["stroke-dashoffset"]
const _hoisted_4 = {
  key: 0,
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  viewBox: "0 0 24 24",
  fill: "none",
  stroke: "currentColor",
  "stroke-width": "2",
  "stroke-linecap": "round",
  "stroke-linejoin": "round"
}
const _hoisted_5 = {
  key: 1,
  src: _imports_0,
  class: "w-5 h-5"
}
const _hoisted_6 = ["src"]

import { ref, computed } from "vue";

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'AudioPlayer',
  props: {
  audioUrl: {
    type: String,
    default: null,
  },
},
  setup(__props) {

const props = __props;

// Refs
const audioPlayer = ref<HTMLAudioElement | null>(null);
const isPlaying = ref(false);
const playCount = ref(0);
const progress = ref(0);

// Computed
const audioSource = computed(() => props.audioUrl);
const circumference = 2 * Math.PI * 12; // 12 is the radius of our circle
const progressOffset = computed(() => {
  return circumference - (progress.value / 100) * circumference;
});

// Methods
const toggleAudio = () => {
  if (isPlaying.value) {
    audioPlayer.value?.pause();
  } else {
    // pause any audio that is playing
    const audioPlayers = document.querySelectorAll("audio");
    audioPlayers.forEach((player) => {
      if (player !== audioPlayer.value) {
        player.pause();
      }
    });
    audioPlayer.value?.play();
  }
  isPlaying.value = !isPlaying.value;
};

const updateProgress = () => {
  if (!audioPlayer.value) return;
  const duration = audioPlayer.value.duration;
  const currentTime = audioPlayer.value.currentTime;
  progress.value = (currentTime / duration) * 100;
};

const onAudioEnded = () => {
  isPlaying.value = false;
  playCount.value++;
  progress.value = 0;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("circle", {
        cx: "16",
        cy: "16",
        r: "12",
        stroke: "#e5e7eb",
        "stroke-width": "2",
        fill: "transparent"
      }, null, -1)),
      _createElementVNode("circle", {
        cx: "16",
        cy: "16",
        r: "12",
        stroke: "#a5b4fc",
        "stroke-width": "2",
        fill: "transparent",
        "stroke-dasharray": circumference,
        "stroke-dashoffset": progressOffset.value
      }, null, 8, _hoisted_3)
    ])),
    _createElementVNode("button", {
      class: "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-8 h-8 flex items-center justify-center disabled:opacity-15",
      onClick: toggleAudio
    }, [
      (isPlaying.value)
        ? (_openBlock(), _createElementBlock("svg", _hoisted_4, _cache[1] || (_cache[1] = [
            _createElementVNode("rect", {
              x: "6",
              y: "5",
              width: "4",
              height: "14"
            }, null, -1),
            _createElementVNode("rect", {
              x: "14",
              y: "5",
              width: "4",
              height: "14"
            }, null, -1)
          ])))
        : (_openBlock(), _createElementBlock("img", _hoisted_5))
    ]),
    _createElementVNode("audio", {
      ref_key: "audioPlayer",
      ref: audioPlayer,
      src: audioSource.value,
      onTimeupdate: updateProgress,
      onEnded: onAudioEnded,
      class: "hidden"
    }, null, 40, _hoisted_6)
  ]))
}
}

})