<template>
  <div class="relative w-8 h-8">
    <svg class="w-full h-full transform -rotate-90">
      <circle cx="16" cy="16" r="12" stroke="#e5e7eb" stroke-width="2" fill="transparent" />
      <circle cx="16" cy="16" r="12" stroke="#a5b4fc" stroke-width="2" fill="transparent" :stroke-dasharray="circumference" :stroke-dashoffset="progressOffset" />
    </svg>
    <button class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-8 h-8 flex items-center justify-center disabled:opacity-15" @click="toggleAudio">
      <svg v-if="isPlaying" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <rect x="6" y="5" width="4" height="14"></rect>
        <rect x="14" y="5" width="4" height="14"></rect>
      </svg>
      <img v-else src="@/assets/icons/speaker.svg" class="w-5 h-5" />
    </button>
    <audio ref="audioPlayer" :src="audioSource" @timeupdate="updateProgress" @ended="onAudioEnded" class="hidden" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";

// Props
const props = defineProps({
  audioUrl: {
    type: String,
    default: null,
  },
});

// Refs
const audioPlayer = ref<HTMLAudioElement | null>(null);
const isPlaying = ref(false);
const playCount = ref(0);
const progress = ref(0);

// Computed
const audioSource = computed(() => props.audioUrl);
const circumference = 2 * Math.PI * 12; // 12 is the radius of our circle
const progressOffset = computed(() => {
  return circumference - (progress.value / 100) * circumference;
});

// Methods
const toggleAudio = () => {
  if (isPlaying.value) {
    audioPlayer.value?.pause();
  } else {
    // pause any audio that is playing
    const audioPlayers = document.querySelectorAll("audio");
    audioPlayers.forEach((player) => {
      if (player !== audioPlayer.value) {
        player.pause();
      }
    });
    audioPlayer.value?.play();
  }
  isPlaying.value = !isPlaying.value;
};

const updateProgress = () => {
  if (!audioPlayer.value) return;
  const duration = audioPlayer.value.duration;
  const currentTime = audioPlayer.value.currentTime;
  progress.value = (currentTime / duration) * 100;
};

const onAudioEnded = () => {
  isPlaying.value = false;
  playCount.value++;
  progress.value = 0;
};
</script>