import { Exam } from "@/interfaces/Exam";
import { required, minLength, minValue, maxValue } from "@vuelidate/validators";
import { format, parseISO } from "date-fns";

/**
 * Creates an initial state for the exam object
 * @returns {Exam} An initial exam state object
 */
export function createInitialExamState(): Exam {
  return {
    id: 0,
    name: '',
    name_en: '',
    name_ar: '',
    duration_id: 0,
    grade_id: null,
    round_id: 0,
    level_id: null,
    exam_type_id: 0,
    platform_id: 0,
    start_date_time: '',
    end_date_time: '',
    min_mark: 0,
    max_mark: 0,
    pass_mark: 0,
    questions_per_page: 0,
    total_mark: 0,
    is_published: false,
    duration: undefined,
    grade: undefined,
    skills: undefined,
    skill_ids: [],
    level: undefined,
    round: undefined,
    platform: undefined,
    type: undefined,
  };
}

/**
 * Formats a date-time string.
 * @param {string | undefined} dateTime - The date-time string to format
 * @returns {string} The formatted date-time string
 */
export function formatDateTime(dateTime: string | undefined): string {
  return dateTime ? format(parseISO(dateTime), "yyyy-MM-dd HH:mm:ss") : '';
}
