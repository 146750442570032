import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "max-w-72" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex flex-col items-center p-8" }
const _hoisted_6 = {
  for: "file-input",
  class: "bg-transparent font-bold cursor-pointer hover:bg-gray-100 rounded-xl p-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.uploadedMediaUrl && _ctx.mediaUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.mediaUrl,
          class: "h-64 w-full min-w-64 object-cover mb-4"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.uploadedMediaUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _ctx.uploadedMediaUrl,
          class: "h-64 w-full min-w-64 object-cover mb-4"
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.previewUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 2,
          src: _ctx.previewUrl,
          class: "h-64 w-full min-w-64 object-cover mb-4"
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true),
    (!_ctx.selectedFile)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: _normalizeClass(["border-2 border-dashed border-gray-300 rounded-md p-5 text-center cursor-pointer transition-colors duration-300 min-w-64", { 'bg-gray-100': _ctx.isDragging }]),
          onDragover: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
          onDrop: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleDrop && _ctx.handleDrop(...args)), ["prevent"])),
          onDragenter: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.isDragging = true), ["prevent"])),
          onDragleave: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleDragLeave && _ctx.handleDragLeave(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_5, [
            _cache[7] || (_cache[7] = _createStaticVNode("<i class=\"mb-4\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"40\" height=\"40\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4\"></path><polyline points=\"17 8 12 3 7 8\"></polyline><line x1=\"12\" y1=\"3\" x2=\"12\" y2=\"15\"></line></svg></i>", 1)),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("common.drag_and_drop")), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("common.or")), 1),
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("common.browse")), 1),
            _createElementVNode("input", {
              id: "file-input",
              type: "file",
              onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFileSelection && _ctx.handleFileSelection(...args))),
              accept: "image/*",
              class: "hidden"
            }, null, 32)
          ])
        ], 34))
      : _createCommentVNode("", true),
    _createElementVNode("a", {
      onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.uploadMedia && _ctx.uploadMedia(...args)), ["prevent"])),
      class: _normalizeClass([{ 'opacity-50 cursor-not-allowed': !_ctx.selectedFile || !_ctx.mediaLabel, 'cursor-pointer': _ctx.selectedFile && _ctx.mediaLabel }, "block w-full mt-3 py-2 px-4 bg-indigo-500 text-white text-center rounded-md"])
    }, _toDisplayString(_ctx.$t("common.upload")), 3),
    _withDirectives(_createElementVNode("a", {
      onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.removeFile && _ctx.removeFile(...args)), ["prevent"])),
      class: _normalizeClass([{ 'opacity-50 cursor-not-allowed': !_ctx.selectedFile || !_ctx.mediaLabel, 'cursor-pointer': _ctx.selectedFile && _ctx.mediaLabel }, "block w-full mt-3 py-2 px-4 bg-red-600 text-white text-center rounded-md"])
    }, _toDisplayString(_ctx.$t("common.remove")), 3), [
      [_vShow, _ctx.selectedFile && _ctx.mediaLabel]
    ])
  ]))
}