<template>
  <Menu as="div" class="relative">
    <!-- Menu Button -->
    <MenuButton class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 ps-3 rtl:ps-8 text-start cursor-pointer focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm hover:bg-indigo-100">
      <span class="block truncate">
        {{ selectedItems.length ? `${selectedItems.length} ${$t("common.selected")}` : placeholder }}
      </span>
      <span class="absolute inset-y-0 rtl:start-0 ltr:end-0 flex items-center pe-2 rtl:ps-2 pointer-events-none">
        <img src="@/assets/icons/arrow-down-dropdown.svg" alt="Arrow Down" class="w-4 h-4" />
      </span>

    </MenuButton>

    <!-- Menu Items -->
    <transition enter-active-class="transition ease-out duration-100" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
      <MenuItems class="absolute mt-1 w-full rounded-lg ring-1 ring-gray-300 bg-white shadow-xl z-10 max-h-60 overflow-auto focus:outline-none text-sm">
        <div class="p-2" v-if="!items.length">
          <div class="text-center text-gray-400 p-2">{{ $t("common.no_data") }}</div>
        </div>

        <!-- Search Input -->
        <div class="p-2" v-if="items.length">
          <input v-model="searchQuery" @keydown.stop type="text" :placeholder="$t('common.search')" class="w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500" />
        </div>

        <!-- Grouped Items -->
        <template v-for="(groupItems, groupName) in filteredGroupedItems" :key="groupName">
          <div class="p-4 border-b border-gray-300">
            <!-- Group Checkbox -->
            <div class="flex items-center font-semibold">
              <input type="checkbox" :id="`group-checkbox-${groupName}`" :checked="isGroupSelected(groupItems)" @change="toggleGroupSelection(groupItems)" class="me-2 h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" />
              <label :for="`group-checkbox-${groupName}`" class="cursor-pointer w-full">{{ groupName || $t("common.all") }}</label>
            </div>

            <!-- Individual Items in Group -->
            <div class="ps-2 py-2">
              <div v-for="item in groupItems" :key="itemValue(item)" @click.stop>
                <div class="flex items-center py-1">
                  <input type="checkbox" :id="`item-checkbox-${itemValue(item)}`" :value="itemValue(item)" v-model="selectedItems" @change="emitSelectionChange" class="me-2 h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" />
                  <label :for="`item-checkbox-${itemValue(item)}`" class="cursor-pointer text-xs w-full">
                    {{ itemLabel(item) }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </template>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from "vue";
import { Menu, MenuButton, MenuItems } from "@headlessui/vue";

export default defineComponent({
  name: "MultiSelectDropdown",
  components: {
    Menu,
    MenuButton,
    MenuItems,
  },
  props: {
    modelValue: {
      type: Array as PropType<(number | string)[]>,
      default: () => [],
      required: true,
    },
    items: {
      type: Array as PropType<any[]>,
      default: () => [],
      required: false,
    },
    groupBy: {
      type: Function as PropType<(item: any) => string>,
      default: null,
    },
    itemLabel: {
      type: Function as PropType<(item: any) => string>,
      required: true,
    },
    itemValue: {
      type: Function as PropType<(item: any) => number | string>,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Select items",
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const selectedItems = ref<(number | string)[]>([...props.modelValue]);
    const searchQuery = ref("");

    const groupedItems = computed(() => {
      if (props.groupBy) {
        return props.items.reduce((acc, item) => {
          const groupName = props.groupBy!(item);
          if (!acc[groupName]) {
            acc[groupName] = [];
          }
          acc[groupName].push(item);
          return acc;
        }, {} as Record<string, any[]>);
      } else {
        return { "": props.items };
      }
    });

    const filteredGroupedItems = computed(() => {
      const query = searchQuery.value.toLowerCase();
      return Object.fromEntries(Object.entries(groupedItems.value as Record<string, any[]>).map(([groupName, items]) => [groupName, items.filter((item: any) => props.itemLabel(item).toLowerCase().includes(query))]));
    });

    const isGroupSelected = (groupItems: any[]) => {
      const groupValues = groupItems.map((item) => props.itemValue(item));
      return groupValues.every((value) => selectedItems.value.includes(value));
    };

    const toggleGroupSelection = (groupItems: any[]) => {
      const groupValues = groupItems.map((item) => props.itemValue(item));
      const allSelected = isGroupSelected(groupItems);

      if (allSelected) {
        // Unselect all items in the group
        selectedItems.value = selectedItems.value.filter((value) => !groupValues.includes(value));
      } else {
        // Select all items in the group
        selectedItems.value = [...new Set([...selectedItems.value, ...groupValues])];
      }

      emitSelectionChange(); // Emit the updated value
    };

    const emitSelectionChange = () => {
      emit("update:modelValue", selectedItems.value);
    };

    return {
      selectedItems,
      searchQuery,
      filteredGroupedItems,
      isGroupSelected,
      toggleGroupSelection,
      emitSelectionChange,
    };
  },
});
</script>
