<template>
  <form @submit.prevent="submitForm">
    <div class="grid grid-cols-2 gap-4 mb-4">
      <div v-for="field in fields" :key="field" class="flex flex-col">
        <!-- Check if the field type is not "object" before rendering -->
        <template v-if="modelFields[field] !== 'object'">
          <label :for="field" class="block text-sm font-semibold text-gray-700 mb-1"> {{ $t(`store.${field}`) }}<span class="text-red-500 ms-1">*</span> </label>

          <!-- Check if field needs a dropdown -->
          <div v-if="isDropdownOptionsLoading && isDropdownField(field)"><Spinner :showLoadingText="true" /></div>
          <SingleSelectDropdown v-else-if="isDropdownField(field)" v-model="formData[field]" :items="dropdownOptions[field]" :itemLabel="(option: any) => option.name" :itemValue="(option: any) => option.id" :placeholder="$t('store.select_one')" :name="field" />

          <!-- Default input field -->
          <input v-else :id="field" v-model="formData[field]" :name="field" class="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </template>
      </div>
    </div>
    <div class="flex justify-end gap-x-2">
      <button @click="$emit('cancel')" type="button" class="me-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-200">{{ $t("common.cancel") }}</button>
      <button type="submit" class="px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600" :class="{ 'opacity-25 pointer-events-none': !isFormValid }">{{ item?.id ? $t("store.update") : $t("store.save") }}</button>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted, PropType } from "vue";
import api from "@/services/apiCrudService";
import { Item } from "@/types";
import { Platform } from "@/interfaces/Platform";
import { JSONCrudModuleObject } from "@/interfaces/CrudModelTypes";

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<Item | null>,
      default: null,
    },
    fields: {
      type: Array as PropType<string[]>,
      required: true,
    },
    selectedPlatform: {
      type: Object as PropType<Platform | null>,
      default: null,
    },
    modelFields: {
      type: Object as PropType<{ [key: string]: string | JSONCrudModuleObject }>,
      required: true,
    },
  },
  emits: ["submit", "cancel"],
  setup(props, { emit }) {
    const formData = ref<Item>({} as Item);
    const isFormValid = ref(false);
    const dropdownOptions = ref<Record<string, any[]>>({});
    const isDropdownOptionsLoading = ref(false);

    const validateForm = () => {
      isFormValid.value = props.fields.every((field) => {
        if (props.modelFields[field] === "object") {
          return true;
        }
        // Validate only if the field is not of type "object"
        return formData.value[field] !== undefined && formData.value[field] !== null && formData.value[field] !== "";
      });
    };

    const loadDropdownOptions = async (field: string) => {
      isDropdownOptionsLoading.value = true;
      try {
        const response = await api.getAll<any[]>(field.replace("_id", "s").replace("_", "-"), {
          filter: {
            platform_id: props.selectedPlatform?.id,
          },
        });
        dropdownOptions.value[field] = response.data;
      } catch (error) {
        console.error(`Error loading dropdown options for ${field}:`, error);
      } finally {
        isDropdownOptionsLoading.value = false;
      }
    };

    const isDropdownField = (field: string) => {
      return field.endsWith("_id");
    };

    watch(
      () => props.item,
      (newItem) => {
        if (newItem) {
          formData.value = { ...newItem };
        } else {
          formData.value = {} as Item;
        }
        validateForm();
      },
      { immediate: true }
    );

    watch(
      () => formData.value,
      () => {
        validateForm();
      },
      { deep: true }
    );

    const submitForm = () => {
      emit("submit", formData.value);
    };

    onMounted(() => {
      props.fields.forEach((field) => {
        if (isDropdownField(field)) {
          loadDropdownOptions(field);
        }
      });
    });

    return {
      formData,
      isFormValid,
      dropdownOptions,
      isDropdownOptionsLoading,
      submitForm,
      isDropdownField,
    };
  },
});
</script>
