<template>
    <div>
      <div ref="editorContainer" class="editor-container"></div>
    </div>
  </template>
  
  <script>
  import { defineComponent, ref, watch, onMounted } from "vue";
  import Quill from "quill";
  
  export default defineComponent({
    name: "RichTextEditor",
    props: {
      modelValue: {
        type: String,
        default: "",
      },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
      const editorContainer = ref(null);
      let quillInstance = null;
  
      onMounted(() => {
        // Get language from localStorage
        const language = localStorage.getItem("language") || "en"; // Default to English
        const isRtl = ["ar", "he", "fa", "ur"].includes(language); // Check for RTL languages
  
        // Initialize Quill editor
        quillInstance = new Quill(editorContainer.value, {
          theme: "snow",
          modules: {
            toolbar: [
              ["bold"], // Bold
              [{ color: [] }], // Text color
            ],
          },
        });
  
        // Apply text direction and alignment based on language
        quillInstance.root.setAttribute("dir", isRtl ? "rtl" : "ltr");
        quillInstance.root.style.textAlign = isRtl ? "right" : "left";
  
        // Set the initial value
        quillInstance.root.innerHTML = props.modelValue;
  
        // Emit changes on text-change
        quillInstance.on("text-change", () => {
          const content = quillInstance.root.innerHTML;
          emit("update:modelValue", content);
        });
      });
  
      // Watch for external changes to modelValue and update Quill
      watch(
        () => props.modelValue,
        (newValue) => {
          if (quillInstance && quillInstance.root.innerHTML !== newValue) {
            quillInstance.root.innerHTML = newValue;
          }
        }
      );
  
      return {
        editorContainer,
      };
    },
  });
  </script>
  
  <style>
  .editor-container {
    border: 1px solid #ccc;
    font-family: inherit;
    font-size: inherit;
    height: 200px;
  }
  </style>
  