/**
 * This module defines a structured map of model fields (`modelFieldsMap`) used to manage
 * various entities in a JSON-based CRUD module. Each entity's fields are described with a 
 * `FieldDescriptor`, specifying the field type (e.g., string, number, boolean, object) or a 
 * nested object structure (`JSONCrudModuleObject`). Common fields such as `id`, `name_en`, 
 * and `name_ar` are shared across most entities for consistency, while others have additional 
 * or specific fields depending on the entity's requirements (e.g., `skill_id`, `grade_id`, 
 * `level_id` for `learning-outcomes`).
 * 
 * The `adjustFieldsForPlatform` function modifies this field map dynamically based on 
 * the `platformIdentifier`. This adjustment ensures that entities like `learning-outcomes` 
 * can adapt their structure based on the current platform (e.g., IRA, ISA). By including 
 * extra fields such as `skill` or `grade` as objects, this modular approach enhances the 
 * flexibility and scalability of the system to support multiple platforms with varying 
 * requirements. The design prioritizes maintainability by centralizing the field definitions 
 * and dynamically adjusting them when needed.
 */

export type FieldType = "string" | "number" | "boolean" | "object"; // Defines allowed field types for entities.

/** 
 * Interface for dynamic JSON objects used within the CRUD module. 
 * Allows for arbitrary key-value pairs where the value can be of any type.
 */
export interface JSONCrudModuleObject {
  [key: string]: any;
}

/** 
 * Field descriptor interface mapping field names to their types or nested objects.
 */
export interface FieldDescriptor {
  [fieldName: string]: FieldType | JSONCrudModuleObject;
}

// Defines a common set of fields shared by many entities in the system.
const commonFields: FieldDescriptor = {
  id: "number",
  name_en: "string",
  name_ar: "string",
};

// A map of model field descriptors for various entities in the application.
export const modelFieldsMap: { [key: string]: FieldDescriptor } = {
  "platforms": commonFields, // Field descriptor for platforms.

  "question-types": commonFields, // Field descriptor for question types.
  "schools": commonFields, // Field descriptor for schools.

  "skills": commonFields, // Field descriptor for skills.
  "rounds": commonFields, // Field descriptor for rounds.
  "grades": commonFields, // Field descriptor for grades.
  "levels": commonFields, // Field descriptor for levels.
  "durations": {
    id: "number",
    number: "number",
    name_en: "string",
    name_ar: "string"
  }, // Field descriptor for durations.

  "cognitive-levels": commonFields, // Field descriptor for cognitive levels.
  "difficulty-levels": commonFields, // Field descriptor for difficulty levels.
  "learning-outcomes": {
    id: "number",
    name_en: "string",
    name_ar: "string",
    skill_id: "number",
    grade_id: "number",
    level_id: "number"
  }, // Field descriptor for learning outcomes.
  "depth-of-knowledges": commonFields, // Field descriptor for depth of knowledge.
};

/**
 * Adjusts the field descriptors dynamically based on the platform identifier.
 * This function ensures that entities like `learning-outcomes` adapt their structure
 * based on platform-specific requirements (e.g., `IRA`, `ISA`).
 * 
 * @param platformIdentifier - The identifier for the current platform (e.g., IRA, ISA).
 * @param fieldsMap - The base map of field descriptors for all entities.
 * @returns A new map with adjusted fields for the specified platform.
 */
export function adjustFieldsForPlatform(platformIdentifier: string, fieldsMap: { [key: string]: FieldDescriptor }) {
  const updatedFieldsMap = { ...fieldsMap }; // Clone the field map for immutability.

  // Adjust fields specifically for the IRA platform.
  if (platformIdentifier === "IRA") {
    updatedFieldsMap["learning-outcomes"] = {
      id: "number",
      name_en: "string",
      name_ar: "string",
      skill_id: "number",
      grade_id: "number",
      skill: "object", // Adds `skill` as a nested object.
      grade: "object" // Adds `grade` as a nested object.
    };
  } 
  // Adjust fields specifically for the ISA platform.
  else if (platformIdentifier === "ISA") {
    updatedFieldsMap["learning-outcomes"] = {
      id: "number",
      name_en: "string",
      name_ar: "string",
      skill_id: "number",
      level_id: "number",
      skill: "object", // Adds `skill` as a nested object.
      level: "object" // Adds `level` as a nested object.
    };
  }

  return updatedFieldsMap; // Return the updated map.
}
