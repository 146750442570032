import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/arrow-down-dropdown.svg'


const _hoisted_1 = { class: "relative" }

import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'LanguageSwitcher',
  setup(__props) {

const { locale } = useI18n();
const currentLanguage = ref(locale.value);

const changeLanguage = (selectedLanguage: string) => {
  locale.value = selectedLanguage;
  currentLanguage.value = selectedLanguage;
  localStorage.setItem("language", selectedLanguage);
  updateDirection(selectedLanguage);
};

const updateDirection = (language: string) => {
  const direction = language === "ar" ? "rtl" : "ltr";
  document.documentElement.setAttribute("dir", direction);
};

watch(currentLanguage, (newLocale) => {
  location.reload(); // Reload the page to fetch new data per language.
  updateDirection(newLocale);
});

updateDirection(currentLanguage.value);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Menu), { as: "div" }, {
      default: _withCtx(() => [
        _createVNode(_unref(MenuButton), { class: "flex justify-between items-center w-full rounded-md border-0 py-1.5 ps-4 pe-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(currentLanguage.value === "en" ? "English" : "العربية"), 1),
            _cache[2] || (_cache[2] = _createElementVNode("img", {
              src: _imports_0,
              alt: "Arrow Down",
              class: "w-4 h-4 absolute end-3"
            }, null, -1))
          ]),
          _: 1
        }),
        _createVNode(_unref(MenuItems), { class: "absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" }, {
          default: _withCtx(() => [
            _createVNode(_unref(MenuItem), {
              as: "button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (changeLanguage('en'))),
              class: "w-full px-4 py-2 text-start text-gray-700 hover:bg-indigo-100"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" English ")
              ])),
              _: 1
            }),
            _createVNode(_unref(MenuItem), {
              as: "button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (changeLanguage('ar'))),
              class: "w-full px-4 py-2 text-start text-gray-700 hover:bg-indigo-100"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" العربية ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})