<template>
  <div class="relative">
    <Menu as="div">
      <MenuButton class="flex justify-between items-center w-full rounded-md border-0 py-1.5 ps-4 pe-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
        <span>{{ currentLanguage === "en" ? "English" : "العربية" }}</span>
        <!-- Arrow Icon -->
        <img src="@/assets/icons/arrow-down-dropdown.svg" alt="Arrow Down" class="w-4 h-4 absolute end-3" />
      </MenuButton>

      <MenuItems class="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <MenuItem as="button" @click="changeLanguage('en')" class="w-full px-4 py-2 text-start text-gray-700 hover:bg-indigo-100"> English </MenuItem>
        <MenuItem as="button" @click="changeLanguage('ar')" class="w-full px-4 py-2 text-start text-gray-700 hover:bg-indigo-100"> العربية </MenuItem>
      </MenuItems>
    </Menu>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

const { locale } = useI18n();
const currentLanguage = ref(locale.value);

const changeLanguage = (selectedLanguage: string) => {
  locale.value = selectedLanguage;
  currentLanguage.value = selectedLanguage;
  localStorage.setItem("language", selectedLanguage);
  updateDirection(selectedLanguage);
};

const updateDirection = (language: string) => {
  const direction = language === "ar" ? "rtl" : "ltr";
  document.documentElement.setAttribute("dir", direction);
};

watch(currentLanguage, (newLocale) => {
  location.reload(); // Reload the page to fetch new data per language.
  updateDirection(newLocale);
});

updateDirection(currentLanguage.value);
</script>
