import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 gap-4 mb-4" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["id", "onUpdate:modelValue", "name"]
const _hoisted_5 = { class: "flex justify-end gap-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_SingleSelectDropdown = _resolveComponent("SingleSelectDropdown")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
        return (_openBlock(), _createElementBlock("div", {
          key: field,
          class: "flex flex-col"
        }, [
          (_ctx.modelFields[field] !== 'object')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("label", {
                  for: field,
                  class: "block text-sm font-semibold text-gray-700 mb-1"
                }, [
                  _createTextVNode(_toDisplayString(_ctx.$t(`store.${field}`)), 1),
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-red-500 ms-1" }, "*", -1))
                ], 8, _hoisted_2),
                (_ctx.isDropdownOptionsLoading && _ctx.isDropdownField(field))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_Spinner, { showLoadingText: true })
                    ]))
                  : (_ctx.isDropdownField(field))
                    ? (_openBlock(), _createBlock(_component_SingleSelectDropdown, {
                        key: 1,
                        modelValue: _ctx.formData[field],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.formData[field]) = $event),
                        items: _ctx.dropdownOptions[field],
                        itemLabel: (option) => option.name,
                        itemValue: (option) => option.id,
                        placeholder: _ctx.$t('store.select_one'),
                        name: field
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "items", "itemLabel", "itemValue", "placeholder", "name"]))
                    : _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: 2,
                        id: field,
                        "onUpdate:modelValue": ($event: any) => ((_ctx.formData[field]) = $event),
                        name: field,
                        class: "block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      }, null, 8, _hoisted_4)), [
                        [_vModelText, _ctx.formData[field]]
                      ])
              ], 64))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel'))),
        type: "button",
        class: "me-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-200"
      }, _toDisplayString(_ctx.$t("common.cancel")), 1),
      _createElementVNode("button", {
        type: "submit",
        class: _normalizeClass(["px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600", { 'opacity-25 pointer-events-none': !_ctx.isFormValid }])
      }, _toDisplayString(_ctx.item?.id ? _ctx.$t("store.update") : _ctx.$t("store.save")), 3)
    ])
  ], 32))
}