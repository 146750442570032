<template>
  <div v-if="show" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" @click.self="$emit('cancel')">
    <div class="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
      <h2 class="text-lg font-semibold mb-4">{{ $t("common.confirm_action_title") }}</h2>
      <p class="mb-6">{{ $t("common.confirm_action_message") }}</p>
      <div class="flex justify-end gap-x-2">
        <button @click="$emit('cancel')" type="button" class="me-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-200">{{ $t("common.cancel") }}</button>
        <button @click="$emit('confirm')" class="px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600">{{ $t("common.confirm") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
